/* =======================
   Parts
   ======================= */

.parts {
	display: flex;

	margin: -10px -14px 0 -15px;

	flex-wrap: wrap;
}

.parts__item {
	display: block;

	width: 68px;
	margin: 10px 14px 0 15px;
	&:hover {
		text-decoration: none;
	}
}

.parts__hero {
	display: flex;

	width: 68px;
	height: 68px;
	margin-bottom: 5px;

	border: 2px solid #ebf0f2;
	background: #f8fbfc;

	align-items: center;
	justify-content: center;
	.parts__item:hover &,
	.parts__item_active & {
		border-color: $green;
		background: $green;
	}
}

.parts__icon {
	display: block;

	fill: $green;
	.parts__item:hover &,
	.parts__item_active & {
		fill: white;
	}
}

.parts__caption {
	font-size: 17px;
	font-weight: bold;
	line-height: 20px;

	text-align: center;
	.parts__item:hover & {
		text-decoration: underline;
	}
}
