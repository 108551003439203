/* =======================
   About
   ======================= */

.about {
	@include last-margin;
	@include media($xl) {
		padding: 70px 30px 45px;
	}
	@include media($lg) {
		padding: 70px 30px 45px;
	}
	@include media($md) {
		padding: 40px 30px;
	}
	@include media($sm) {
		padding: 30px 15px;
	}

	max-width: 1140px;
	margin: 0 auto;

	text-align: center;
}

.about__title {
	@include media($xl) {
		font-size: 34px;
	}
	@include media($lg) {
		font-size: 34px;
	}
	@include media($md) {
		font-size: 30px;
	}
	@include media($sm) {
		font-size: 24px;
	}

	font-weight: bold;
	line-height: 1;

	margin: 0 0 1em;

	text-align: center;

	color: $black;
}
