/* =======================
   Content
   ======================= */

.content {
	@include last-margin;
	@include media($xl) {
		.sidebar + & {
			float: left;

			width: 75%;
		}
	}
	@include media($lg) {
		.sidebar + & {
			float: left;

			width: 75%;
		}
	}
	ul {
		margin: 0 0 1.42em 2em;
		padding: 0;

		list-style: none;
		li {
			&:before {
				display: block;
				float: left;

				width: 6px;
				height: 6px;
				margin: .55em 0 0 -1.42em;

				content: '';

				border-radius: 50%;
				background: $black;
			}
		}
	}
	ol {
		margin: 0 0 1.42em 2em;
		padding: 0;

		list-style: none;

		counter-reset: list;
		li {
			counter-increment: list;
			&:before {
				font-weight: bold;

				float: left;

				margin-left: -1.42em;

				content: counter(list)'.';

				color: $black;
				border-radius: 50%;
			}
		}
	}
}

.f-left {
	@include media($sm) {
		float: none;

		margin-right: 0;
	}

	float: left;

	margin: 0 1.42em 1em 0;
}

.f-right {
	@include media($sm) {
		float: none;

		margin-left: 0;
	}

	float: right;

	margin: 0 0 1em 1.42em;
}
