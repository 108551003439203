/* =======================
   Form
   ======================= */

// Button
.btn {
	font-size: 17px;
	font-weight: bold;
	line-height: 20px;

	display: inline-block;

	padding: 8px 25px 7px;

	color: white;
	border: 0;
	background: $green;
	.icon-arrow-right {
		font-size: 20px;

		margin: -2px .25em 0 0;

		vertical-align: top;
	}
	.icon-angle-right {
		margin: 1px 0 0;

		vertical-align: top;
	}
	&-md {
		padding: 11px 25px;
	}
	&-lg {
		padding: 15px 25px;
	}
	&-xl {
		padding: 15px 19px;
	}
	&-offer {
		display: block;

		width: 100%;

		text-align: center;
	}
	&:hover {
		text-decoration: none;

		color: white;
		background: $brightgreen;
	}
}

// Checkbox
.checkbox {
	position: relative;

	padding-left: 25px;
}

.checkbox__input {
	position: absolute;
	top: 0;
	left: 0;

	visibility: hidden;
	clip: rect(0,0,0,0);

	width: 0;
	height: 0;

	opacity: 0;
}

.checkbox__label {
	cursor: pointer;
	&:before {
		display: inline-block;
		float: left;

		width: 15px;
		height: 15px;
		margin: .2em 0 0 -25px;

		content: '';
		cursor: pointer;

		border: 1px solid #ccd5dc;
		outline: none;
		background-color: #eff4f8;

		appearance: none;
	}
	.checkbox__input:checked + &:before {
		@include hidpi {
			background-image: url(../i/tick@2x.png);
			background-size: 9px 7px;
		}

		background-image: url(../i/tick.png);
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}
}

// Select
.select {
	font-size: 16px;
	line-height: 1.25;

	position: relative;

	display: block;
	overflow: hidden;

	width: 100%;
	height: 35px;
	padding: 6px 44px 6px 11px;

	cursor: pointer;
	white-space: nowrap;

	border: 1px solid #ccd5dc;
	border-radius: 0;
	outline: none;
	background: #eff4f8 url(../i/arrow.svg) no-repeat 100% 0;
	background-size: 33px 33px;

	appearance: none;
	&::-ms-expand {
		display: none;
	}
}

// Inputs
.form__input,
.form__textarea {
	font-size: 17px;
	line-height: 21px;

	display: block;

	width: 100%;
	height: 35px;
	padding: 6px 11px;

	border: 1px solid #dee4e8;
	outline: none;
	background: white;
	&.error {
		color: #ed1c24;
		border-color: #f27f7f;
		background: #fbe5e5;
	}
}

.form__textarea {
	height: auto;
}

// Form layout
.form {
	@include last-margin;
	@include media($sm) {
		margin: 30px -15px;
		padding: 30px 15px;
	}

	max-width: 878px;
	margin: 30px 0;
	padding: 30px;

	background: #ebf0f2;
}

.form__title {
	font-size: 24px;
	font-weight: bold;
	line-height: 1;

	margin-bottom: .75em;

	color: $black;
}

.form__group {
	@include media($sm) {
		margin-bottom: 20px;
	}

	margin-bottom: 20px;
	&-short {
		max-width: 618px;
	}
}

.form__label {
	font-size: 18px;
	font-weight: bold;

	margin-bottom: .5em;

	color: $black;
}

.form__columns {
	@include clearfix;
	@include media($sm) {
		@include last-margin;

		margin-bottom: 20px;
	}

	margin: 0 -15px 20px;
	&-short {
		max-width: 648px;
	}
}

.form__column {
	@include last-margin;
	@include media($sm) {
		float: none;

		width: auto;
		margin-bottom: 20px;
	}

	float: left;

	width: 50%;
	padding: 0 15px;
}

.form__note {
	font-size: 13px;

	margin-top: 1px;

	color: $black;
}

.form__error {
	font-size: 13px;

	margin-top: 1px;

	text-align: right;

	color: #ed1c24;
}

.form__required {
	color: #ed1c24;
}
