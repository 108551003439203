/* =======================
   Nav
   ======================= */

.nav {
	@include media($md) {
		padding-top: 60px;
	}
	@include media($sm) {
		padding-top: 60px;
	}

	position: relative;
	z-index: 2;

	background: $blue;
}

.nav__toggle {
	@include media($xl) {
		display: none;
	}
	@include media($lg) {
		display: none;
	}

	position: absolute;
	top: 13px;
	right: 30px;

	width: 34px;
	height: 34px;

	cursor: pointer;

	border: 2px solid white;
	border-radius: 2px;
	background: white;
	&:before,
	&:after,
	& > i {
		position: absolute;
		left: 5px;

		display: block;

		width: 20px;
		height: 2px;

		content: '';

		background: $blue;
	}
	&:before {
		top: 7px;
	}
	&:after {
		top: 21px;
	}
	& > i {
		top: 14px;
	}
	.nav_open & {
		background: $green;
		&:before,
		&:after,
		& > i {
			background: white;
		}
	}
}

.nav__list {
	@include reset-ul;
	@include media($xl) {
		display: flex;
		flex-direction: row;

		padding: 0 15px;
	}
	@include media($lg) {
		display: flex;
		flex-direction: row;

		padding: 0 15px;
	}
	@include media($md) {
		display: none;
		.nav_open & {
			display: block;
		}
	}
	@include media($sm) {
		display: none;
		.nav_open & {
			display: block;
		}
	}

	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.nav__item {
	@include media($xl) {
		flex-grow: 1;
	}
	@include media($lg) {
		flex-grow: 1;
	}
	@include media($md) {
		border-top: 1px solid #49bbc2;
	}
	@include media($sm) {
		border-top: 1px solid #49bbc2;
	}

	position: relative;
	&:before {
		@include media($md) {
			display: none;
		}

		position: absolute;
		top: 50%;
		left: 0;

		width: 1px;
		height: 15px;
		margin-top: -8px;

		content: '';

		background: #89e6ec;
	}
	&:first-child:before {
		display: none;
	}
	&-home {
		@include media($md) {
			display: none;
		}
		@include media($sm) {
			display: none;
		}
	}
}

.nav__link {
	@include media($xl) {
		line-height: 60px;

		text-align: center;
	}
	@include media($lg) {
		line-height: 60px;

		text-align: center;
	}
	@include media($md) {
		line-height: 25px;

		padding: 10px 30px;

		color: white;
		background: $darkblue;
	}
	@include media($sm) {
		font-size: 18px;
		line-height: 25px;

		padding: 10px 15px;

		color: white;
		background: $darkblue;
	}

	font-size: 19px;
	font-weight: bold;

	position: relative;

	display: block;

	color: $black;
	&-arrow:after {
		font-family: iconfont;
		line-height: 1;

		margin-left: .25em;

		content: '\e900';
		.nav__item_open & {
			content: '\e909';
		}
	}
	.icon-home {
		font-size: 23px;

		display: inline-block;

		transform: translateY(2px);
	}
	&:hover {
		@include media($xl) {
			z-index: 2;

			margin-right: -1px;
			padding-right: 1px;

			background: $darkblue;
		}
		@include media($lg) {
			z-index: 2;

			margin-right: -1px;
			padding-right: 1px;

			background: $darkblue;
		}

		text-decoration: none;

		color: white;
	}
	.nav__item_active & {
		@include media($xl) {
			z-index: 3;

			margin-right: -1px;
			padding-right: 1px;

			color: white;
			background: #29afb7;
			box-shadow: 0 5px 0 white inset;
		}
		@include media($lg) {
			z-index: 3;

			margin-right: -1px;
			padding-right: 1px;

			color: white;
			background: #29afb7;
			box-shadow: 0 5px 0 white inset;
		}
	}
	.nav__item_open & {
		@include media($md) {
			color: #95f8fe !important;
			background: #24a2a9;
		}
		@include media($sm) {
			color: #95f8fe !important;
			background: #24a2a9;
		}
	}
}

.nav__sm {
	@include reset-ul;
	@include media($xl) {
		position: absolute;
		top: 100%;
		left: 0;
	}
	@include media($lg) {
		position: absolute;
		top: 100%;
		left: 0;
	}
	@include media($md) {
		background: white;
	}
	@include media($sm) {
		background: white;
	}

	display: none;

	min-width: 100%;
	.nav__item_open & {
		display: block;
	}
}

.nav__smItem {
	padding-top: 1px;
}

.nav__smLink {
	@include media($sm) {
		padding-right: 15px;
		padding-left: 27px;
	}

	font-size: 17px;
	font-weight: bold;

	display: block;

	padding: 7px 30px 7px 42px;

	white-space: nowrap;

	background: #f8fbfc;
	&:before {
		font-family: iconfont;
		font-weight: normal;

		float: left;

		margin-left: -12px;

		content: '\e903';
	}
	&:hover {
		text-decoration: none;

		color: white;
		background: $brightgreen;
	}
}
