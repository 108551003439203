/* =======================
   Product
   ======================= */

.product {
	@include media($sm) {
		display: block;
	}

	display: flex;

	margin-bottom: 30px;
}

.product__leftside {
	@include last-margin;
	@include media($sm) {
		width: auto;
		margin-bottom: 30px;
	}

	width: 41.2%;
}

.product__rightside {
	@include last-margin;
	@include media($sm) {
		width: auto;
		padding-left: 0;
	}

	width: 58.8%;
	padding-left: 30px;
}
