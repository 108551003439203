/* =======================
   Gallery
   ======================= */

.gallery {
	display: flex;

	margin: 0 -15px 30px;

	flex-wrap: wrap;
}

.gallery__item {
	@include media($xl) {
		&:nth-child(-n+4) {
			margin-top: 0;
		}
	}
	@include media($lg) {
		&:nth-child(-n+4) {
			margin-top: 0;
		}
	}
	@include media($md) {
		&:nth-child(-n+4) {
			margin-top: 0;
		}
	}
	@include media($sm) {
		width: 33.3333%;
		&:nth-child(-n+3) {
			margin-top: 0;
		}
	}
	@include media($xs) {
		width: 50%;
		&:nth-child(3) {
			margin-top: 20px;
		}
		&:nth-child(-n+2) {
			margin-top: 0;
		}
	}

	width: 25%;
	margin-top: 20px;
	padding: 0 15px;
}

.gallery__hero {
	position: relative;

	display: block;
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		display: block;

		content: '';
		transition: opacity .2s ease;

		opacity: 0;
		background: $black;
	}
	&:after {
		font-family: iconfont;
		font-size: 24px;
		line-height: 1;

		position: absolute;
		top: 50%;
		left: 50%;

		display: block;

		width: 24px;
		height: 24px;
		margin: -12px 0 0 -12px;

		content: '\e912';
		transition: opacity .2s ease;

		opacity: 0;
		color: white;
	}
	&:hover {
		text-decoration: none;
		&:before {
			opacity: .4;
		}
		&:after {
			opacity: 1;
		}
	}
}

.gallery__pic {
	display: block;

	width: 100%;
	height: auto;
	margin-right: auto;
	margin-left: auto;
}

.gallery__caption {
	font-size: 15px;

	margin-top: 5px;

	text-align: center;
}
