/* =======================
   More link
   ======================= */

.morelink {
	font-size: 17px;
	&:before {
		font-family: iconfont;
		font-size: 20px;
		line-height: 1;

		display: inline-block;

		margin: 0 .35em 0 0;

		content: '\e907';
		transform: translateY(2px);
	}
	&:hover {
		text-decoration: none;
	}
	&-categories {
		color: white;
	}
}

.morelink__text {
	font-weight: bold;
	.morelink:hover & {
		text-decoration: underline;
	}
}
