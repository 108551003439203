/* =======================
   Header
   ======================= */

.header {
	@include media($xl) {
		height: 113px;
	}
	@include media($lg) {
		height: 113px;
	}
	@include media($md) {
		height: 83px;
	}
	@include media($sm) {
		height: 83px;
	}

	position: relative;

	max-width: 1230px;
	margin: 0 auto;
}

.header__logo {
	@include hide-text;
	@include media($xl) {
		top: 20px;
		left: 30px;

		width: 174px;
		height: 70px;
	}
	@include media($lg) {
		top: 20px;
		left: 30px;

		width: 174px;
		height: 70px;
	}
	@include media($md) {
		top: 14px;
		left: 30px;

		width: 137px;
		height: 55px;
	}
	@include media($sm) {
		top: 14px;
		left: 15px;

		width: 137px;
		height: 55px;
	}

	position: absolute;

	background: url(../i/logo.svg) no-repeat 50% 50%;
	background-size: contain;
}

.header__slogan {
	@include media($xl) {
		top: 35px;
		left: 255px;

		height: 54px;
		padding-top: 15px;
		padding-left: 44px;
	}
	@include media($lg) {
		top: 35px;
		left: 215px;

		height: 54px;
		padding-top: 15px;
		padding-left: 14px;
	}
	@include media($md) {
		line-height: 2.35;

		top: 26px;
		left: 180px;

		padding-left: 14px;
	}
	@include media($sm) {
		display: none;
	}

	font-size: 18px;
	line-height: 20px;

	position: absolute;

	color: #757575;
	border-left: 1px solid #d9d9d9;
}
