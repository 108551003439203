/* =======================
   Related
   ======================= */

.related {
	@include media($xs) {
		padding-bottom: 30px;
	}

	position: relative;

	margin: 30px 0;
}

.related__title {
	@extend .about__title;

	text-align: left;
}

.related__slider {
	position: relative;

	overflow: hidden;

	margin: 0 -15px;
	&:before,
	&:after {
		position: absolute;
		z-index: 2;
		top: 0;
		bottom: 0;

		display: block;

		width: 15px;

		content: '';

		background: white;
	}
	&:before {
		left: 0;
	}
	&:after {
		right: 0;

		box-shadow: 1px 0 0 white;
	}
}

.related__item {
	@include media($xl) {
		width: 33.3333%;
	}
	@include media($lg) {
		width: 33.3333%;
	}
	@include media($md) {
		width: 33.3333%;
	}
	@include media($sm) {
		width: 50%;
	}
	@include media($xs) {
		width: 100%;
	}

	position: relative;

	height: auto;
	padding: 15px 30px;
	&:before {
		@include media($xs) {
			display: none;
		}

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;

		display: block;

		width: 1px;

		content: '';

		background: #d9d9d9;
	}
}

.related__nav {
	@include media($xs) {
		position: absolute;
		bottom: 0;
		left: 50%;

		float: none;

		margin-left: 0;

		transform: translateX(-50%);
	}

	display: flex;
	float: right;

	width: 75px;
	margin-left: 15px;

	justify-content: space-between;
}

.related__prev,
.related__next {
	font-size: 17px;
	line-height: 26px;

	width: 30px;
	height: 30px;

	cursor: pointer;
	text-align: center;

	border: 2px solid #e9e9e9;
	border-radius: 50%;
	&:before {
		font-family: iconfont;

		color: $green;
	}
	&:hover {
		border-color: $green;
	}
	&.swiper-button-disabled {
		cursor: default;
		&:before {
			color: #e9e9e9;
		}
		&:hover {
			border-color: #e9e9e9;
		}
	}
}

.related__prev:before {
	content: '\e901';
}

.related__next:before {
	content: '\e902';
}
