/* =======================
   Footer
   ======================= */

.footer {
	font-size: 14px;

	color: white;
	background: $darkblue;
}

.footer__inner {
	@include media($sm) {
		display: block;

		padding: 20px 15px;
	}

	display: flex;

	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 40px 30px;

	align-items: center;
	justify-content: space-between;
}

.footer__siteinfo {
	@include media($sm) {
		margin-bottom: 20px;
	}

	font-weight: bold;

	text-transform: uppercase;
}

.footer__social {
	@include media($xl) {
		display: flex;

		align-items: center;
	}
	@include media($lg) {
		display: flex;

		align-items: center;
	}
	@include media($sm) {
		margin-bottom: 20px;
	}
}

.footer__socialCaption {
	@include media($xl) {
		margin-right: 10px;
	}
	@include media($lg) {
		margin-right: 10px;
	}
	@include media($md) {
		margin-bottom: 5px;
	}
	@include media($sm) {
		margin-bottom: 5px;
	}

	font-weight: bold;
}

.footer__socialList {
	display: flex;

	align-items: center;
	flex-wrap: wrap;
}

.footer__socialLink {
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;

	width: 22px;
	height: 22px;
	margin-right: 10px;

	text-align: center;
	text-decoration: none;

	color: $darkblue;
	border-radius: 3px;
	background: white;
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		text-decoration: none;

		color: $blue;
	}
}

.footer__dev {
	font-weight: bold;
}

.footer__devLink {
	text-decoration: none;

	color: white;
	&:before {
		font-family: iconfont;
		font-size: 26px;
		font-weight: normal;
		line-height: 1;

		display: inline-block;

		width: 26px;
		height: 26px;
		margin: -4px .2em 0 0;

		content: '\e914';
		vertical-align: top;
	}
	&:hover {
		text-decoration: none;
	}
}

.footer__devText {
	.footer__devLink:hover & {
		text-decoration: underline;
	}
}
