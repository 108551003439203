/* =======================
   Sellers
   ======================= */

.sellers {
	margin-bottom: 30px;
}

.sellers__item {
	margin-bottom: 20px;
	padding: 0 0 20px;

	border-bottom: 1px solid #d9d9d9;

	&:last-child {
		margin-bottom: 0;
		padding-bottom: 0;

		border-bottom: 0;
	}
}

.sellers__header {
	margin-bottom: .5em;
}

.sellers__name {
	@include media($sm) {
		display: block;

		margin-right: 0;
	}

	font-weight: bold;

	display: inline-block;

	margin-right: 1em;

	color: $black;
}

.sellers__maplink {
	cursor: pointer;
	user-select: none;

	border-bottom: 1px dashed;
    color: #38c883;

	&:hover {
		text-decoration: none;

		border-bottom: 0;
	}
}

.sellers__adr {
	margin-bottom: .5em;
}
