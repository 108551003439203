/* =======================
   Video
   ======================= */

.video {
	max-width: 560px;
	margin: 0 0 30px;
}

.video__inner {
	position: relative;

	width: 100%;
	padding-bottom: 56.25%;
	iframe {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}
}
