/* =======================
   Crumbs
   ======================= */

.crumbs {
	font-size: 0;

	margin-bottom: 20px;
	padding-bottom: 20px;

	color: #757575;
	border-bottom: 1px solid #d9d9d9;
}

.crumbs__item {
	font-size: 15px;

	display: inline;
	&:after {
		font-family: iconfont;

		display: inline;

		margin: 0 .75em;

		content: '\e910';
		vertical-align: top;
	}
	&:last-child:after {
		display: none;
	}
}
