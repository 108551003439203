/* =======================
   Search
   ======================= */

.search {
	@include media($xl) {
		top: 46px;
		left: 600px;

		width: 255px;
		padding-right: 28px;

		border-bottom: 1px solid #d9d9d9;
	}
	@include media($lg) {
		top: 46px;
		left: 500px;

		width: 255px;
		padding-right: 28px;

		border-bottom: 1px solid #d9d9d9;
	}
	@include media($md) {
		z-index: 3;
		top: 96px;
		left: 30px;

		width: 235px;
		height: 34px;
		padding-right: 34px;

		border-bottom: 0;
		border-radius: 2px;
		background: white;
	}
	@include media($sm) {
		z-index: 3;
		top: 96px;
		left: 15px;

		width: 182px;
		height: 34px;
		padding-right: 34px;

		border-bottom: 0;
		border-radius: 2px;
		background: white;
	}

	position: absolute;

    .twitter-typeahead {
        width: 100%;
    }

    .tt-menu {
        position: absolute;
        top: 100%;
        left: 0;
        right: -34px;
        z-index: 999;
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        background-color: #fff;
        border-top: 1px solid #d9d9d9;
        box-shadow: 0 3px 1px rgba(0,0,0,0.05);

        @include media($lg) {
            right: -28px;
            margin-top: 7px;
        }
        @include media($xl) {
            right: -28px;
            margin-top: 7px;
        }
    }
}

.search__input {
	@include media($xl) {
		height: 28px;
		padding: 3px;
	}
	@include media($lg) {
		height: 28px;
		padding: 3px;
	}
	@include media($md) {
		height: 34px;
		padding: 6px 10px;
	}
	@include media($sm) {
		height: 34px;
		padding: 6px 10px;
	}

	font-size: 18px;
	font-weight: bold;
	line-height: 22px;

	display: block;

	width: 100%;

	color: $green;
	border: 0;
	outline: none;
	background: none;
	&::placeholder {
		color: $green;
	}

    &.tt-hint {
        color: #d3d3d3;
    }
    .tt-rendered.tt-open & {
        border-radius: 2px 0 0 2px;
    }
}

.search__btn {
	@include media($xl) {
		line-height: 28px;

		width: 28px;
		height: 28px;
	}
	@include media($lg) {
		line-height: 28px;

		width: 28px;
		height: 28px;
	}
	@include media($md) {
		line-height: 34px;

		width: 34px;
		height: 34px;
		height: 34px;
	}
	@include media($sm) {
		line-height: 34px;

		width: 34px;
		height: 34px;
		height: 34px;
	}

	font-size: 22px;

	position: absolute;
	top: 0;
	right: 0;

	display: block;

	padding: 0;

	text-align: center;

	color: $green;
	border: 0;
	outline: none;
	background: none;
	&:before {
		font-family: iconfont;

		content: '\e912';
	}
}

.search__result {
    display: table;
    padding-top: 5px;
    padding-bottom: 5px;
    table-layout: fixed;
    clear: both;
    color: #262626;

    &.tt-cursor,
    &:hover {
        cursor: pointer;
        text-decoration: none;
        outline: 0;
        background-color: #ddfbed;
    }

    p {
        margin: 0;
    }
}

.search__result-photo {
    display: table-cell;
    vertical-align: top;
    width: 62px;
    padding-left: 12px;
    padding-right: 10px;
}

.search__result-photo-img {
    display: block;
    width: 100%;
}

.search__result-text {
    display: table-cell;
    vertical-align: middle;
    padding-right: 12px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
}