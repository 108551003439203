/* =======================
   Offers
   ======================= */

.offers {
	@include media($sm) {
		padding: 20px 15px;
	}

	margin-bottom: 30px;
	padding: 30px 15px 20px;

	background: #f8fbfc;
}

.offers__title {
	@include media($sm) {
		font-size: 18px;
		line-height: 1.25;

		margin-bottom: 20px;
		padding: 0;
	}

	font-size: 24px;
	line-height: 1;

	margin-bottom: 30px;
	padding: 0 15px;

	color: $black;
}

.offers__list {
	border-top: 1px solid #e4eaec;
}

.offers__item {
	@include media($sm) {
		padding: 15px 0;
	}

	display: flex;

	border-bottom: 1px solid #e4eaec;

	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	&:hover {
		@include media($sm) {
			background: none;
		}

		background: white;
	}
}

.offers__caption {
	@include media($sm) {
		font-size: 16px;

		width: 100%;
		margin-bottom: 10px;
		padding: 0;
	}

	font-size: 18px;
	font-weight: bold;

	width: calc(50% - 81px);
	padding: 15px;

	color: $black;
}

.offers__info {
	@include media($sm) {
		width: calc(100% - 132px);
		padding: 0;
	}

	width: calc(50% - 81px);
	padding: 15px;
}

.offers__delivery {
	font-size: 16px;
	line-height: 1.375;

	position: relative;

	padding-left: 32px;
	&:before {
		position: absolute;
		top: .1875em;
		left: 0;

		display: block;

		width: 24px;
		height: 16px;

		content: '';

		background: url(../i/truck.svg) no-repeat 50% 50%;
	}
}

.offers__pickup {
	font-size: 16px;
	line-height: 1.375;

	position: relative;

	padding-left: 32px;
	&:before {
		position: absolute;
		top: .1875em;
		left: 6px;

		display: block;

		width: 18px;
		height: 16px;

		content: '';

		background: url(../i/cart.svg) no-repeat 50% 50%;
	}
}

.offers__btn {
	@include media($sm) {
		width: 132px;
		padding: 0 0 0 15px;
	}

	width: 162px;
	padding: 15px;
}
