/* =======================
   Bottom navigation
   ======================= */

.bnav {
	@include reset-ul;
	@include media($sm) {
		@include last-margin;

		display: block;

		padding: 15px;
	}

	display: flex;

	max-width: 1230px;
	margin: 0 auto;
	padding: 0 30px;

	align-items: center;
	justify-content: space-between;
}

.bnav__item {
	@include media($sm) {
		margin-bottom: 10px;
	}
}

.bnav__separator {
	@include media($md) {
		display: none;
	}
	@include media($sm) {
		display: none;
	}

	width: 1px;
	height: 15px;

	background: #d6dbdc;
}

.bnav__link {
	@include media($xl) {
		font-size: 17px;
		line-height: 20px;

		padding: 15px 0;
	}
	@include media($lg) {
		font-size: 17px;
		line-height: 20px;

		padding: 15px 0;
	}
	@include media($md) {
		font-size: 15px;
		line-height: 20px;

		padding: 15px 0;
	}
	@include media($sm) {
		font-size: 17px;
		line-height: 1;

		display: inline;
	}

	font-weight: bold;

	display: block;

	color: $black;
	&:hover {
		text-decoration: none;

		color: $green;
	}
	.bnav__item_active & {
		@include media($sm) {
			border-bottom: 3px solid $blue;
			box-shadow: none;
		}

		box-shadow: 0 5px 0 $blue inset;
	}
}
