/* =======================
   Contacts
   ======================= */

.contacts {
	margin-bottom: 1.42em;
	p {
		margin-bottom: 1em;
	}
}

.contacts__caption {
	margin-bottom: .5em;
}

.contacts__list {
	@include last-margin;

	margin-top: 1em;
}

.contacts__item {
	margin-bottom: 2px;
}

.contacts__map {
	margin-top: 1.42em;
}

.contacts__scrollto {
	margin-left: .5em;

	border-bottom: 1px dashed;
	&:hover {
		text-decoration: none;
	}
}

.contacts__toggle {
	margin-left: .5em;
	&:after {
		font-family: iconfont;

		margin-left: .25em;

		content: '\e900';
	}
	&:hover {
		text-decoration: none;
	}
	&_active:after {
		content: '\e909';
	}
}

.contacts__toggleText {
	border-bottom: 1px dashed;
}

.contacts__map {
	display: none;
}
