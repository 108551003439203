/* =======================
   Container
   ======================= */

.container {
	@include clearfix;
	@include media($xl) {
		padding: 36px 30px 40px;
	}
	@include media($lg) {
		padding: 36px 30px 40px;
	}
	@include media($md) {
		padding: 30px;
	}
	@include media($sm) {
		padding: 30px 15px;
	}

	max-width: 1230px;
	margin: 0 auto;
}
