.social-networks {

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		margin-bottom: -10px;
		padding: 0;
		list-style: none;
	}

	&__item {
		margin-right: 10px;
		margin-bottom: 10px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 22px;
		height: 22px;
		border-radius: 3px;
		font-size: 0;
		background-color: #fff;

		&::before {
			content: "";
			display: block;
			width: 16px;
			height: 16px;
			background-repeat: no-repeat;
			background-position: center center;
		}

		&--vkontakte::before {
			background-image: url("../i/social-networks/vkontakte.svg");
		}

		&--facebook::before {
			background-image: url("../i/social-networks/facebook.svg");
		}

		&--instagram::before {
			background-image: url("../i/social-networks/instagram.svg");
		}
	}
}
