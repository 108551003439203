/* =======================
   Language
   ======================= */

.lang {
	@include media($xl) {
		top: 42px;
		right: 45px;
	}
	@include media($lg) {
		top: 42px;
		right: 45px;
	}
	@include media($md) {
		top: 28px;
		right: 30px;
	}
	@include media($sm) {
		top: 28px;
		right: 15px;
	}

	position: absolute;
}

.lang__item {
	font-size: 18px;
	font-weight: bold;
	line-height: 28px;

	float: left;

	min-width: 40px;
	margin-left: 1px;

	text-align: center;
	&_active {
		color: #ababab;
		border-bottom: 5px solid $blue;
	}
}

.lang__link {
	display: block;

	color: $green;
	border-bottom: 5px solid transparent;
	&:hover {
		text-decoration: none;

		border-bottom-color: $green;
	}
}
