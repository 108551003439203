/* =======================
   Secondary nagiation
   ======================= */

.snav {
	@include reset-ul;
	@include last-margin;
}

.snav__item {
	margin-bottom: 1px;
}

.snav__link {
	font-size: 17px;
	font-weight: bold;

	display: block;

	padding: 7px 15px 7px 45px;

	background: #f8fbfc;
	&:before {
		font-family: iconfont;
		font-size: 20px;
		font-weight: normal;
		line-height: 1;

		display: block;
		float: left;

		width: 45px;
		margin: 2px 0 0 -45px;

		content: '\e907';
		text-align: center;
	}
	&:hover {
		text-decoration: none;
	}
	.snav__item_active & {
		text-decoration: none;

		color: white;
		background: $green;
	}
}

.snav__text {
	.snav__link:hover & {
		text-decoration: underline;
		.snav__item_active & {
			text-decoration: none;
		}
	}
}
