/* =======================
   Base
   ======================= */

*,
:before,
:after {
	box-sizing: border-box;
}

html {
	background: white;
}

body {
	@include media($sm) {
		font-size: 16px;
	}

	font-family: $ptsans;
	font-size: 17px;
	line-height: 1.42;

	color: $grey;
}

a {
	text-decoration: none;

	color: $green;
	&:hover {
		text-decoration: underline;
	}
}

p {
	margin: 0 0 1.42em;
}

h1 {
	@include media($xl) {
		font-size: 34px;
	}
	@include media($lg) {
		font-size: 34px;
	}
	@include media($md) {
		font-size: 30px;
	}
	@include media($sm) {
		font-size: 24px;
	}

	font-weight: bold;
	line-height: 1;

	margin: 0 0 .75em;

	color: $black;
}

h2 {
	@include media($sm) {
		font-size: 18px;
	}

	font-size: 24px;
	font-weight: bold;
	line-height: 1;

	margin: 0 0 .75em;

	color: $black;
}

h3 {
	@include media($sm) {
		font-size: 16px;
	}

	font-size: 18px;
	font-weight: bold;

	margin: 0 0 .75em;

	color: $black;
}

table {
	margin: 30px 0;
}

th {
	font-size: 14px;
	font-weight: bold;

	padding: 13px 15px;

	text-align: left;
	text-transform: uppercase;

	color: white;
	border-right: 1px solid #3b3b3b;
	border-left: 1px solid #3b3b3b;
	background: $black;
	&:first-child {
		border-left-color: $black;
	}
	&:last-child {
		border-right-color: $black;
	}
}

td {
	font-size: 15px;

	padding: 12px 15px;

	border-right: 1px solid #eef4f9;
	border-left: 1px solid #eef4f9;
	background: white;
	tr:nth-child(2n+1) & {
		@include media($sm) {
			background: #f9fafb;
		}

		border-right: 1px solid #dbe6ec;
		border-left: 1px solid #dbe6ec;
		background: #ebf0f2;
		&:first-child {
			border-left-color: #ebf0f2;
		}
		&:last-child {
			border-right-color: #ebf0f2;
		}
	}
	tr:last-child & {
		border-bottom: 1px solid #ebf0f2;
	}
}

table.responsive {
	font-size: 14px;

	display: block;

	margin: 1.25em 0;

	border-top: 1px solid #dbe6ec;
	tbody {
		display: block;

		width: 100%;
	}
	tr {
		display: block;
	}
	th {
		display: none;
	}
	td {
		position: relative;

		display: block;

		width: 100%;
		padding: .75em 14px;

		border-top: 0;
		border-right: 1px solid #dbe6ec !important;
		border-bottom: 1px solid #dbe6ec !important;
		border-left: 1px solid #dbe6ec !important;
		&.responsive-td {
			padding-left: calc(45% + 15px);
		}
	}
}

.responsive-th {
	position: absolute;
	top: 0;
	left: 0;

	width: 45%;
	padding: .75em 15px .75em 14px;
}

hr {
	height: 1px;
	margin: 1.42em 0;
	padding: 0;

	border: 0;
	background: #d9d9d9;
}
