.reviews {

	@include media($xl) {
		padding: 70px 30px 0;
	}

	@include media($lg) {
		padding: 70px 30px 0;
	}

	@include media($md) {
		padding: 40px 15px;
		padding-bottom: 0;
	}

	@include media($sm) {
		padding: 30px 15px 0;
	}

	max-width: 1230px;
	margin: 0 auto;

	&__title {
		@extend .about__title;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		margin-bottom: -40px;
		padding: 0;
		list-style: none;

		@include media($sm) {
			max-width: 540px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: -30px;
		}

		@include media(max-width 530px) {
			max-width: 360px;
		}
	}

	&__item {
		width: calc((99.9% - 30px * 2) / 3);
		margin-right: 30px;
		margin-bottom: 40px;

		&:nth-of-type(3n) {
			margin-right: 0;
		}

		@include media($sm) {
			width: calc((99.9% - 30px) / 2);
			margin-bottom: 30px;

			&:nth-of-type(3n) {
				margin-right: 30px;
			}

			&:nth-of-type(2n) {
				margin-right: 0;
			}
		}

		@include media(max-width 530px) {
			width: 100%;
			margin-right: 0;

			&:nth-of-type(3n) {
				margin-right: 0;
			}
		}
	}

	&__link {
		display: inline-block;
		vertical-align: top;
		max-width: 100%;
		margin-bottom: 20px;
		transition: 0.2s;

		@include media($sm) {
			margin-bottom: 12px;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	&__image {

		& img {
			display: inline-block;
			vertical-align: top;
			max-width: 100%;
		}
	}

	&__name {
		margin-bottom: 6px;
		font-size: 28px;
		font-weight: 700;
		color: $black;

		@include media($sm) {
			margin-bottom: 8px;
			font-size: 24px;
		}
	}

	&__description {
		margin-bottom: 16px;
		font-size: 17px;
		line-height: 1.45;

		@include media($sm) {
			font-size: 16px;
		}

		& > :last-child {
			margin-bottom: 0;
		}

		& p {
			margin: 0;
			margin-bottom: 20px;
		}
	}

	&__btn {

	}
}