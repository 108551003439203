/* =======================
   Rubrics
   ======================= */

.rubrics {
	@include media($sm) {
		display: block;

		margin: 0 0 30px;
	}

	display: flex;

	margin: 0 -15px 30px;
}

.rubrics__item {
	@include media($sm) {
		width: 100%;
		max-width: 290px;
		margin: 20px auto 0;
	}

	font-size: 16px;
	line-height: 1.25;

	display: block;

	width: calc(33.3333% - 30px);
	margin: 30px 15px 0;

	color: $grey;
	background: #f8fbfc;
	&:nth-child(-n+3) {
		@include media($sm) {
			margin-top: 20px;
		}

		margin-top: 0;
	}
	&:first-child {
		@include media($sm) {
			margin-top: 0;
		}
	}
	&:hover {
		text-decoration: none;

		color: white;
		background: $green;
	}
}

.rubrics__hero {
	display: block;

	width: 100%;
	height: auto;
}

.rubrics__info {
	@include last-margin;

	position: relative;

	padding: 20px;

	text-align: center;
	&:before {
		@include triangle(18px 8px, #f8fbfc, up);

		position: absolute;
		bottom: 100%;
		left: 50%;

		display: block;

		content: '';
		transform: translateX(-50%);
		.rubrics__item:hover & {
			border-bottom-color: $green;
		}
	}
}

.rubrics__caption {
	font-size: 18px;
	font-weight: bold;

	margin-bottom: .5em;

	color: $green;
	.rubrics__item:hover & {
		text-decoration: underline;

		color: white;
	}
}
