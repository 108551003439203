/* =======================
   Advantages
   ======================= */

.advantages {
	color: white;
	background: $blue;
}

.advantages__inner {
	@include media($xl) {
		padding: 45px 30px 55px;
	}
	@include media($lg) {
		padding: 45px 30px 45px;
	}
	@include media($md) {
		padding: 40px 30px;
	}
	@include media($sm) {
		padding: 30px 15px;
	}

	max-width: 1230px;
	margin: 0 auto;

	text-align: center;
}

.advantages__title {
	@extend .about__title;
	@include media($sm) {
		text-align: left;
	}

	color: white;
}

.advantages__list {
	@include media($sm) {
		@include last-margin;
	}

	display: flex;

	flex-wrap: wrap;
}

.advantages__item {
	@include media($xl) {
		width: 16.6666%;
		padding: 0 15px;
	}
	@include media($lg) {
		width: 16.6666%;
		padding: 0 15px;
	}
	@include media($md) {
		width: 33.3333%;
		margin-top: 30px;
		padding: 0 15px;
		&:nth-child(-n+3) {
			margin-top: 0;
		}
	}
	@include media($sm) {
		display: flex;

		width: 100%;
		margin-bottom: 20px;

		text-align: left;

		align-items: center;
		justify-content: space-between;
	}

	text-align: center;
	&:before {
		@include media($sm) {
			margin: 0;

			align-self: flex-start;
		}

		display: block;

		width: 46px;
		height: 46px;
		margin: 0 auto 18px;

		content: '';

		background-repeat: no-repeat;
		background-position: 50% 50%;
	}
	&-health:before {
		background-image: url(../i/health.svg);
	}
	&-environment:before {
		background-image: url(../i/environment.svg);
	}
	&-euro:before {
		background-image: url(../i/euro.svg);
	}
	&-quality:before {
		background-image: url(../i/quality.svg);
	}
	&-control:before {
		background-image: url(../i/control.svg);
	}
	&-animals:before {
		background-image: url(../i/animals.svg);
	}
}

.advantages__caption {
	@include media($sm) {
		line-height: 1.25;

		width: calc(100% - 66px);
	}

	font-size: 17px;
	font-weight: bold;
	&:before {
		@include media($sm) {
			display: none;
		}

		display: block;

		width: 30px;
		height: 1px;
		margin: 0 auto 20px;

		content: '';

		background: #86e7ed;
	}
}
