/* =======================
   News
   ======================= */

.news {
	@include last-margin;

	margin: 0 0 20px;
}

.news__item {
	@include media($sm) {
		display: block;
	}

	display: flex;

	margin-bottom: 20px;
	padding-bottom: 20px;

	border-bottom: 1px solid #d9d9d9;
}

.news__hero {
	@include media($sm) {
		width: auto;
		margin-bottom: 1em;
		padding-right: 0;
	}

	width: 180px;
	padding-right: 15px;
}

.news__pic {
	display: block;

	max-width: 100%;
	height: auto;
}

.news__body {
	@include last-margin;
	@include media($sm) {
		width: auto;
	}

	width: calc(100% - 180px);
}

.news__time {
	font-size: 14px;

	margin-bottom: .5em;
}

.news__caption {
	font-weight: bold;

	margin-bottom: .25em;
}
