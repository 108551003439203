/* =======================
   Paging
   ======================= */

.paging {
	@include media($sm) {
		padding: 0 30px;
	}

	font-weight: bold;
	line-height: 26px;

	position: relative;

	display: flex;

	padding: 0 90px;

	justify-content: center;
}

.paging__prev,
.paging__next {
	@include media($sm) {
		top: 0;

		width: 30px;
	}

	position: absolute;
	top: 2px;

	width: 90px;

	text-transform: uppercase;
}

.paging__prev {
	left: 0;

	text-align: left;
}

.paging__next {
	right: 0;

	text-align: right;
}

.paging__prevLink,
.paging__nextLink {
	@include media($sm) {
		display: block;

		width: 30px;
		height: 30px;

		text-align: center;

		border: 2px solid;
		border-radius: 50%;
	}

	font-size: 16px;

	color: $green;
	&:hover {
		text-decoration: none;

		color: $brightgreen;
	}
}

.paging__prevText,
.paging__nextText {
	@include media($sm) {
		display: none;
	}
}

.paging__prevLink {
	&:before {
		@include media($sm) {
			margin: 0;
		}

		font-family: iconfont;
		font-weight: normal;

		display: inline-block;

		margin: 0 .25em 0 0;

		content: '\e901';
		// vertical-align: top;
	}
}

.paging__nextLink {
	&:after {
		@include media($sm) {
			margin: 0;
		}

		font-family: iconfont;
		font-weight: normal;

		display: inline-block;

		margin: 0 0 0 .25em;

		content: '\e902';
		// vertical-align: top;
	}
}

.paging__item {
	width: 30px;
	height: 30px;

	text-align: center;

	color: $grey;
	border: 2px solid transparent;
	border-radius: 50%;
	&_active {
		border-color: #eaeaea;
	}
}

.paging__link {
	display: block;

	width: 26px;
	height: 26px;

	text-align: center;

	color: $green;
	&:hover {
		text-decoration: none;

		color: $brightgreen;
	}
}
