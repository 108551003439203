/* =======================
   Use
   ======================= */

.use {
	margin-bottom: 1.42em;
	padding: 15px;

	background: #f8fbfc;
}

.use__columns {
	@include media($xs) {
		@include last-margin;

		display: block;

		margin: 0;
	}

	display: flex;

	margin: 0 -15px;
}

ul.use__list {
	@include media($xs) {
		margin-bottom: 10px;
		padding: 0;
	}

	font-size: 15px;
	line-height: 16px;

	width: 100%;
	min-width: 50%;
	margin: 0;
	padding: 0 15px;
	li.use__listItem {
		margin-bottom: 10px;
		padding-left: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			float: left;

			width: 12px;
			height: 12px;
			margin: 1px 0 0 -20px;

			border-radius: 0;
			background: url(../i/use-bullet.svg) no-repeat 50% 50%;
			background-size: contain;
		}
	}
}
