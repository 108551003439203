/* =======================
   Categories
   ======================= */

.categories {
	@include last-margin;
	@include media($xl) {
		padding: 0 30px;
	}
	@include media($lg) {
		padding: 0 30px;
	}
	@include media($md) {
		padding: 0 15px;
	}
	@include media($sm) {
		padding: 0 15px;
	}

	max-width: 1230px;
	margin: 0 auto;
}

.categories__item {
	@include media($sm) {
		display: block;

		height: auto;
		margin-bottom: 2px;
	}

	display: flex;

	height: 440px;
	margin-bottom: 10px;
}

.categories__hero {
	@include media($sm) {
		width: 100%;
		height: 165px;

		order: 0;
	}

	display: block;

	width: 66.6666%;

	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;

	order: 2;
}

.categories__info {
	@include last-margin;
	@include media($lg) {
		line-height: 1.25;
	}
	@include media($md) {
		font-size: 16px;
		line-height: 1.25;

		padding: 20px 30px;
	}
	@include media($sm) {
		display: block;

		width: 100%;
		padding: 20px 15px;

		text-align: center;
	}

	position: relative;

	display: flex;
	flex-direction: column;

	width: 33.3333%;
	padding: 30px;

	color: white;
	background: $blue;

	justify-content: center;
	&:after {
		@include media($sm) {
			@include triangle(24px 12px, $blue, up);

			position: absolute;
			bottom: 100%;
			left: 50%;

			display: block;

			content: '';
			transform: translateX(-50%);
		}
	}
	.categories__item:nth-child(2n+1) & {
		order: 1;
		&:before {
			@include triangle(24px 12px, $blue, right);
			@include media($sm) {
				display: none;
			}

			position: absolute;
			top: 72px;
			left: 100%;

			display: block;

			content: '';
		}
	}
	.categories__item:nth-child(2n) & {
		order: 3;
		&:before {
			@include triangle(24px 12px, $blue, left);@include media($sm) {
				display: none;
			}

			position: absolute;
			top: 72px;
			right: 100%;

			display: block;

			content: '';
		}
	}
}

.categories__caption {
	@include media($xl) {
		font-size: 34px;

		margin-bottom: 1em;

		text-align: center;
	}
	@include media($lg) {
		font-size: 34px;

		margin-bottom: .75em;

		text-align: center;
	}
	@include media($md) {
		font-size: 24px;

		margin-bottom: .5em;
	}
	@include media($sm) {
		font-size: 24px;

		margin-bottom: .8em;
	}

	font-weight: bold;
	line-height: 1;
}

.categories__footer {
	@include media($xl) {
		text-align: center;
	}
	@include media($lg) {
		text-align: center;
	}
}
