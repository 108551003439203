@charset 'UTF-8';

// Fonts
$ptsans: 'PT Sans', sans-serif;

// Colors
$grey: #595959;
$black: #272727;
$green: #38c883;
$brightgreen: #25d27f;
$blue: #5bccd3;
$darkblue: #29afb7;

// Breakpoints
$xl: min-width 1230px;
$lg: min-width 980px max-width 1229px;
$md: min-width 768px max-width 979px;
$sm: max-width 767px;
$xs: max-width 479px;